<!--
	程序版本：V3.0
	版权所有：深圳市科飞时速网络科技有限公司
	技术支持：Tech@21gmail.com
	单元名称：对账单列表页面
	开始时间：2022-03-03
	开发人员：刘巍骏
	最后修改：2022-03-03
	备注说明：如需修改请联系开发人员
-->
<template>
  <div class="billingview" id="check_tableBox">
    <!-- 操作框 -->
    <div class="billingview_headoperate">
      <!-- 标题 -->
      <div class="head_operateList">
        <ul class="list">
          <li @click="findCheckBillsMainByCondition(0)" :class="{'selLi':checkViewButton==0}">
            全部
          </li>
          <li @click="findCheckBillsMainByCondition(1)" :class="{'selLi':checkViewButton==1}">
            已审
          </li>
          <li @click="findCheckBillsMainByCondition(2)" :class="{'selLi':checkViewButton==2}">
            未审
          </li>
          <li>
            <el-popover placement="bottom-start" width="400" v-model="show_searchBox" trigger="manual">
              <div class="billingview_searchTitle">
                <span>根据以下条件搜索:</span>
              </div>
              <el-form ref="form" :model="checkSearchData" size="mini" label-width="100px">
                <el-form-item label="单号:">
                  <el-input v-model="checkSearchData.billingNumber" size="mini" placeholder="请输入内容" clearable>
                  </el-input>
                </el-form-item>
                <el-form-item label="供应商:" v-if="loginType == 0">
                  <el-input v-model="checkSearchData.supplierName" size="mini" placeholder="请输入内容"
                    @focus="judgeRelatedData(1)" clearable></el-input>
                </el-form-item>
                <el-form-item label="客户:" v-if="loginType == 0">
                  <el-input v-model="checkSearchData.customerName" size="mini" placeholder="请输入内容"
                    @focus="judgeRelatedData(4)" clearable></el-input>
                </el-form-item>
                <el-form-item label="部门名:" v-if="loginType == 0">
                  <el-input v-model="checkSearchData.departName" size="mini" placeholder="请输入内容"
                    @focus="judgeRelatedData(2)" clearable></el-input>
                </el-form-item>
                <el-form-item label="员工姓名:" v-if="loginType == 0">
                  <el-input v-model="checkSearchData.userName" size="mini" placeholder="请输入内容"
                    @focus="judgeRelatedData(3)" clearable></el-input>
                </el-form-item>
                <el-form-item label="起止日期:">
                  <el-col :span="10">
                    <el-date-picker type="date" placeholder="选择日期" v-model="checkSearchData.startTime"
                      style="width: 100%;"></el-date-picker>
                  </el-col>
                  <el-col class="line" :span="3" :push="1"> - </el-col>
                  <el-col :span="10">
                    <el-date-picker type="date" placeholder="选择日期" v-model="checkSearchData.endTime"
                      style="width: 100%;"></el-date-picker>
                  </el-col>
                </el-form-item>
                <el-form-item label="单据类型:">
                  <el-select v-model="checkSearchData.billsType" placeholder="请选择单据类型" style="width: 100%;" clearable>
                    <el-option value="" key="-1" label="全部"></el-option>
                    <el-option v-for="(item,index) in billsModelData" :key="item.id" :label="item.bills_type"
                      :value="item.id" v-show="item.type_classify == checkType">
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="单据状态:">
                  <el-select v-model="checkSearchData.billsStatus" placeholder="请选择状态" style="width: 100%;" clearable>
                    <el-option value="" key="-1" label="全部"></el-option>
                    <el-option v-for="elem in costCheckState" :label="elem.name" :value="elem.value">{{elem.name}}
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="备注:">
                  <el-input v-model="checkSearchData.remark" size="mini" placeholder="请输入内容" clearable></el-input>
                </el-form-item>
                <el-form-item>
                  <div class="billingview_searchBtnBox">
                    <el-button @click="findCheckBillsMainByCondition(5)" type="primary">搜索</el-button>
                    <el-button @click="show_searchBox=false">取消</el-button>
                  </div>
                </el-form-item>
              </el-form>
              <span slot="reference" class="billingview_searchBut" @click="showSearchBox">
                查询<i class="el-icon-search"></i>
              </span>
            </el-popover>
          </li>
          <li @click="findCheckBillsMainByCondition(checkViewButton)">
            刷新<i class="el-icon-refresh-right"></i>
          </li>
          <li class="noHover" v-if="filterPower && filterPower.departmentType!=3 && loginType == 0">
            <userPowerSelect :departmentPowerData="departmentPower" :userPowerType="userPowerType"
              @getSelectData="getSelectUserPowerData"></userPowerSelect>
          </li>
        </ul>
      </div>

      <!-- 操作列表 -->
      <div class="head_operateList">
        <ul class="list">
          <li @click="addBillsData">
            <i class="el-icon-plus"></i>新增
          </li>
          <li @click="operateCost(0)">
            <i class="el-icon-check"></i>提交
          </li>
          <li @click="operateCost(1)" v-if="loginType == 0">
            <i class="el-icon-document-checked"></i>审批
          </li>
          <li @click="operateCost(3)">
            <i class="el-icon-document-checked"></i>对账确认
          </li>
          <li>
            <i class="el-icon-document-copy"></i>复制
          </li>
          <li>
            <billExport :billsTypeSign="9" :billsModelData="billsModelData" :billsClassify="checkType"
              :originBillData="checkSelData"></billExport>
          </li>
        </ul>
      </div>
    </div>

    <!-- 表格 -->
    <div class="billingview_tablewrapper billing_table">
      <el-table ref="costCheckTableList" :data="checkViewData" :summary-method="getSummaries" show-summary
        @select-all="getSelectedAllData" @select="getSelectedData" height="100%" border style="width: 100%">
        <!-- 索引 -->
        <el-table-column type="index" fixed width="35">
          <template slot="header" slot-scope="scope">
            <el-tooltip placement="bottom-start" effect="light">
              <div slot="content">
                <ul class="billsList_relvanceTips scollStyle">
                  <li v-for="elem in showListFiledData" v-if="elem.classify == -1 || elem.classify == checkType">
                    <el-checkbox v-model="elem.is_show">{{elem.name}}</el-checkbox>
                  </li>
                </ul>
              </div>
              <i class="el-icon-setting"></i>
            </el-tooltip>
          </template>
        </el-table-column>
        <!-- 全选 -->
        <el-table-column type="selection" fixed width="30"></el-table-column>
        <!-- 操作 -->
        <el-table-column label="操作" fixed width="50">
          <template slot-scope="scope">
            <div v-if="scope.row.isSubmit<4">
              <el-tooltip placement="right" effect="light">
                <div slot="content">
                  <ul class="billsList_relvanceTips scollStyle">
                    <!-- <li v-if="scope.row.isSubmit<4" @click="updateBillData(scope.row,1)">
                      <i class="el-icon-edit-outline operate_icon" title="修改"></i>
                      <span class="left_padding_10">修改</span>
                    </li> -->
                    <li @click="updateBillData(scope.row,2)">
                      <i class="el-icon-view operate_icon" title="预览"></i>
                      <span class="left_padding_10">预览</span>
                    </li>
                    <li v-if="[1,3].includes(scope.row.isSubmit)" @click="judgeRejectBills(scope.row)">
                      <i class="el-icon-warning-outline operate_icon" title="驳回"></i>
                      <span class="left_padding_10">驳回</span>
                    </li>
                    <li v-if="scope.row.isSubmit<4" @click="judgeDeleteBills(scope.row)">
                      <i class="el-icon-delete operate_icon" title="作废"></i>
                      <span class="left_padding_10">作废</span>
                    </li>
                  </ul>
                </div>
                <i class="el-icon-more icon_color_red"></i>
              </el-tooltip>
            </div>
            <div v-if="scope.row.isSubmit >= 4">
              <i class="el-icon-view operate_icon" title="预览" @click="updateBillData(scope.row,2)"></i>
            </div>
          </template>
        </el-table-column>
        <!-- 数据内容 -->
        <el-table-column prop="billsNo" label="单号" sortable width="150" v-if="showListFiledArr.includes(0)"
          show-overflow-tooltip>
          <template slot-scope="scope">
            <span class="span_pointer span_color_blue" title="点击查看"
              @click="updateBillData(scope.row,2)">{{scope.row.billsNo}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="billsDate" label="单据日期" width="120" v-if="showListFiledArr.includes(1)"
          show-overflow-tooltip></el-table-column>
        <el-table-column prop="billingTypeInfo.bills_type" label="单据类型" width="100" v-if="showListFiledArr.includes(2)"
          show-overflow-tooltip></el-table-column>
        <el-table-column prop="isSubmit" label="单据状态" width="80" v-if="showListFiledArr.includes(3)">
          <template slot-scope="scope">
            <!-- 驳回显示 -->
            <div v-if="scope.row.isSubmit == 2">
              <rejectBillTips :originBillId="scope.row.id" :originBillState="scope.row.isSubmit"
                :billsSign="billsTypeSign"></rejectBillTips>
            </div>

            <!-- 其它显示 -->
            <div v-else>
              <el-button v-for="(elem,index) in costCheckState" :type="elem.class" size="mini" :style="{background:elem.color,border:'none'}"
                v-if="scope.row.isSubmit == elem.value">
                {{elem.name}}
              </el-button>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="receiptState" label="发票状态" width="80"
          v-if="showListFiledArr.includes(16)">
          <template slot-scope="scope">
            <relvanceBillTipStrip :originBillId="scope.row.id" :stateKeyName="'invoiceState'"
              :originBillState="scope.row.receiptState" :originBillStateType="'9.1'"
              @getRelvanceDetail="getRelvanceDetail"></relvanceBillTipStrip>
          </template>
        </el-table-column>
        <!-- 收款单特殊字段 -->
        <el-table-column prop="customerName" label="客户名称" v-if="checkType == 5 && showListFiledArr.includes(4)"
          width="240" show-overflow-tooltip>
        </el-table-column>
        <!-- 付款单特殊字段 -->
        <el-table-column prop="supplierName" label="供应商名称" v-if="checkType == 6 && showListFiledArr.includes(5)"
          width="240" show-overflow-tooltip>
        </el-table-column>
        <!-- 公共字段 -->
        <el-table-column prop="totalAmount" label="单据金额" width="80" v-if="showListFiledArr.includes(6)"
          show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="createUserName" label="制单人" width="80" v-if="showListFiledArr.includes(7)"
          show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="createTime" label="制单时间" width="180" v-if="showListFiledArr.includes(8)"
          show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="checkUserName" label="审核人" width="80" v-if="showListFiledArr.includes(9)"
          show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="checkTime" label="审核时间" width="180" v-if="showListFiledArr.includes(10)"
          show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="deptName" label="部门" width="150" v-if="showListFiledArr.includes(11)"
          show-overflow-tooltip></el-table-column>
        <el-table-column prop="saleUserName" label="员工" width="150" v-if="showListFiledArr.includes(12)"
          show-overflow-tooltip></el-table-column>
        <!-- <el-table-column prop="printNum" label="打印次数" width="80" v-if="showListFiledArr.includes(13)"
          show-overflow-tooltip></el-table-column>
        <el-table-column prop="exportNum" label="导出次数" width="80" v-if="showListFiledArr.includes(14)"
          show-overflow-tooltip>
        </el-table-column> -->
        <el-table-column prop="remark" label="备注" min-width="150" v-if="showListFiledArr.includes(15)"
          show-overflow-tooltip></el-table-column>
      </el-table>
    </div>

    <!-- 表格分页 -->
    <div class="billing_tablePage">
      <el-pagination @size-change="changePageSize" @current-change="changeCurrentPage"
        :current-page="pageData.currentPage" :page-sizes="[10, 20, 30, 40, 50]" :page-size="pageData.pageSize"
        layout="total, sizes, prev, pager, next, jumper" :total="pageData.totalPage">
      </el-pagination>
    </div>

    <!-- 弹框 -->
    <!-- 作废条件弹框 -->
    <el-dialog title="作废原因" :visible.sync="show_delete" width="500px" append-to-body>
      <el-input type="textarea" :rows="4" placeholder="请输入内容" v-model="deleteData">
      </el-input>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="show_delete = false">取 消</el-button>
        <el-button size="small" type="primary" @click="deleteCostData">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 驳回条件弹框 -->
    <el-dialog title="驳回原因" :visible.sync="show_reject" width="500px" append-to-body>
      <el-input type="textarea" :rows="4" placeholder="请输入内容" v-model="rejectData">
      </el-input>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="show_reject = false">取 消</el-button>
        <el-button size="small" type="primary" @click="rejectBills">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 工时信息弹框(下一节点工时信息为员工填写时用到) -->
    <el-dialog title="预计工时" :visible.sync="show_hourseBox" width="400px" :before-close="closeHourseBox" append-to-body>
      <!-- 工时信息弹框 -->
      <div class="billing_dialog_form">
        <ul class="single_ul">
          <li>
            <div class="title">下节点预计工时:</div>
            <div class="mulData">
              <el-input size="small" class="mulInput" type="number" v-model.number="nextHourse" placeholder="请输入工时时长">
              </el-input>
              <span class="mulSpan" v-if="nextNodeBaseInfo.working_hours_type == 1">天</span>
              <span class="mulSpan" v-if="nextNodeBaseInfo.working_hours_type == 2">小时</span>
            </div>
          </li>
        </ul>
      </div>

      <!-- 提交按钮 -->
      <span slot="footer" class="dialog-footer">
        <el-button size="small" type="primary" @click="commitNodeData(nextNodeBaseInfo,nextHourse)">确认</el-button>
        <el-button size="small" @click="closeHourseBox">取消</el-button>
      </span>
    </el-dialog>

    <!-- 新增单据组件 -->
    <nodeBills :nodeTypeCode="nodeTypeCode" :billsClassify="billsClassify" :billsTypeSign="billsTypeSign"
      :updateDefaultData="updateDefaultData" :operateType="operateType" :generateBillsId="generateBillsId"
      :billsTypeDefaultCode="billsTypeDefaultCode" v-if="show_nodeBills">
    </nodeBills>

    <!-- 部门选择弹框 -->
    <departmentTree @SelectedData="getDepartmentData" v-if="show_departmentBox"></departmentTree>

    <!-- 供应商选择弹框 -->
    <supplierList @SelectedData="getSupplierData" v-if="show_supplierBox"></supplierList>

    <!-- 员工选择组件 -->
    <personnelList @SelectedData="getPersonnelData" v-if="show_personnelBox"></personnelList>

    <!-- 客户选择弹框 -->
    <customerList @SelectedData="getCustomerData" v-if="show_customerBox"></customerList>
  </div>
</template>

<script>
  // 导入vuex
  import {
    mapMutations,
    mapState,
    mapActions
  } from 'vuex';

  //导入api接口
  import workApi from '@/network/system/workflowSetup.js'; //工作流程相关接口请求
  import costApi from '@/network/cost/account.js'; //资金模块
  import api from '@/network/hr/organization.js'; //人力模块

  // 导入组件
  import nodeBills from '@/components/commComponent/billing/nodeBills'; //新建单据公共组件
  import departmentTree from '@/components/commComponent/tree/tree_department'; //部门选择组件
  import supplierList from '@/components/commComponent/list/list_supplier'; //供应商列表组件
  import customerList from '@/components/commComponent/list/list_customer'; //客户列表组件
  import personnelList from '@/components/commComponent/list/list_personnel' //部门员工组件
  import userPowerSelect from '@/components/commComponent/power/user_power_select'; //用户权限查询组件
  import billExport from '@/components/commComponent/dialog/bill_export'; //单据打印组件
  import rejectBillTips from '@/components/commComponent/tooltip/rejectBillTips'; //单据驳回信息显示组件
  import relvanceBillTipStrip from '@/components/commComponent/tooltip/relvanceBillTipStrip.vue'; //单据关联其它单据信息

  export default {
    name: 'checkView',
    data() {
      return {
        //控制开关
        show_nodeBills: false, //控制显示公共单据组件弹框
        show_searchBox: false, //是否显示搜索
        checkViewButton: 0, //当前选中查询条件(0:全部 1:已审 2:未审 3:刷新 4:高级查询)
        show_delete: false, //控制作废弹框是否显示
        show_reject: false, //控制驳回弹框是否显示
        show_departmentBox: false, //控制部门选择弹框
        show_supplierBox: false, //控制加载供应商选择弹框
        show_personnelBox: false, //控制加载用户选择弹框
        show_customerBox: false, //控制加载客户选择弹框

        //当前组件数据
        checkViewData: [], //列表页数据
        checkSelData: [], //对账单列表已选数据
        checkCurrSelData: {}, //对账单当前操作单据
        deleteData: '', //作废条件框里的值
        rejectData: '', //驳回框里的值
        showListFiledData: [], //列表可显示的字段
        showListFiledArr: [], //列表需要显示的

        //分页数据
        pageData: {
          currentPage: 1, //当前页
          totalPage: 0, //总页数
          pageSize: 20 //页面大小
        },

        //节点相关
        show_hourseBox: false, //控制显示工时信息弹框
        nextHourse: 2, //下一节点预计工时
        nextNodeBaseInfo: {}, //下一节点基本信息

        //传入子组件的数据
        nodeTypeCode: 'SYS-NODE-TYPE-005', //节点分类code(资金)
        billsTypeSign: 9, //单据分类标签(9代表收对账单)
        billsClassify: 5, //单据分类(5:对账单)
        generateBillsId: -1, //需要生成其他单据的id
        operateType: 0, //操作类型(0:新增 1:修改  2:查看)
        billsTypeDefaultCode: '', //默认单据类型
        updateDefaultData: { //修改时默认流程和节点等信息
          nodeId: -1, //节点id
          processId: -1, //流程id
        },
        //用户查询权限子组件
        userPowerType: 1, //默认全公司
        // 高级搜索框数据
        checkSearchData: { //高级搜索数据
          billingNumber: '', //单号
          supplierName: '', //供应商
          supplierId: '', //供应商id
          customerName: '', //客户名
          customerId: '', //客户id
          deptId: '', //部门id
          departName: '', //部门名
          userName: '', //员工名称
          userId: '', //员工id
          billsType: '', //单据类型
          startTime: '', //开始时间
          endTime: '', //结束时间
          billsStatus: '', //单据状态
          remark: '' //备注
        },
        billsModelData: {}, //单据模板
        personnel: -1, //员工
        departmentPower: [], //权限部门
        filterPower: {}, //符合条件的权限对象
      }
    },
    mounted() {
      // 初始化界面所需参数(vuex数据)
      this.initVuextData();
      //初始化列表显示字段数据
      this.initListShowFiledData();
    },
    updated() {
      this.$nextTick(() => {
        //表格加载处理
        this.$refs['costCheckTableList'].doLayout();
      });
    },
    computed: {
      ...mapState({
        loginType: state => state.user.loginType, //当前系统登录方式
        LoginCustomerInfo: state => state.user.LoginCustomerInfo, //当前系统登录的客户信息
        LoginSupplierInfo: state => state.user.LoginSupplierInfo, //当前系统登录供应商信息
        bills_operate_btn: state => state.commComponent.bills_operate_btn, //新建单据弹框操作按钮
        billTemplateData: state => state.system.billTemplateData, //所有单据类型数据
        userPowerInfo: state => state.user.userPowerInfo, //权限
        UserInfo: state => state.user.UserInfo, //当前登录用户信息
        firstLevelPersonnelData: state => state.hr.firstLevelPersonnelData, //一级部门数据
        checkListFileData: state => state.bill_list_config.checkListFileData, //对账单列表可显示字段
        costCheckState: state => state.bill_state.costCheckState, //对账状态
      }),
      //路由传入的参数(对账单据类型(0:收款单  1:付款单))
      checkType() {
        //路由获取的参数需要转换为int类型
        return Number(this.$route.query.checkType);
      },
      //路由传入的默认单据类型code
      checkBillCode() {
        return this.$route.query.checkBillCode;
      }
    },
    watch: {
      //监听新建弹框关闭或显示
      show_nodeBills(newVal) {
        if (!newVal && this.bills_operate_btn != 4) {
          //设置请求数据类型
          this.billsClassify = this.checkType;
          //获取列表数据
          this.findCheckBillsMainByCondition(this.checkViewButton);
        }
      },
      // 监听显示列表数据发生变化
      showListFiledData: {
        handler(newVal) {
          this.getCurShowFiledArr();
        },
        deep: true
      },
      // 监听路由发生变化
      '$route': 'getPathChange',
    },
    methods: {
      ...mapMutations([
        'SHOW_SUPPLIERBOX', //控制供应商弹框是否显示
        'SHOW_DEPARTMENTBOX', //控制部门弹框是否显示
        'SHOW_PERSONNELBOX', //控制部门员工弹框是否显示
        'SHOW_CUSTOMERBOX', //控制客户弹框是否显示
      ]),
      ...mapActions([
        'getelectronicTemplate', //获取所有单据类型
        'getSysCostBankInfo', //收款账号
        'getAllUserData', //请求企业用户数据
        'getAllManufactureType', //生产类型
        'getUserPowerInfo', //获取用户操作权限
      ]),

      /* 初始化界面所需数据vuex数据 */
      async initVuextData() {
        // 发送请求前加载loading框
        this.loading = this.commonJsExtend.customLoading("#check_tableBox", 4, '列表初始化中,请稍候...');
        //判断用户权限
        if (this.userPowerInfo.length == 0) {
          await this.getUserPowerInfo();
        }
        //获取单据类型数据
        this.getBillTemplateData();
        //判断权限信息
        await this.getUserPower();
        this.loading.close();
        //获取全部列表数据
        this.findCheckBillsMainByCondition(0);
      },

      /* 监听路由发生变化执行方法 */
      getPathChange() {
        //获取单据类型数据
        this.getBillTemplateData();
        //获取全部列表数据
        this.findCheckBillsMainByCondition(0);
      },

      /* 获取单据类型 */
      async getBillTemplateData() {
        //判断单据模板数据是否存在
        if (this.billTemplateData.length == 0) {
          await this.getelectronicTemplate();
        }
        if (this.billTemplateData.length != 0) {
          //根据分类获取单据分类信息
          this.billsModelData = this.commonJsExtend.getBillsClassifyDataByType(2, this.nodeTypeCode, this
            .billTemplateData).children;
          //根据code设置默认值
          if (!!this.checkBillCode) {
            if (this.checkBillCode == 'DOC-C-018') { //对账单
              this.checkSearchData.billsType = '';
            } else {
              this.checkSearchData.billsType = this.commonJsExtend.getBillsDataByType(2, this.checkBillCode, this
                .billTemplateData).id
            }
          }
        }
      },

      /* 获取用户权限 */
      async getUserPower() {
        //判断是否有用户权限数据
        if (this.userPowerInfo) {
          //获取符合条件的权限对象
          this.filterPower = this.userPowerInfo.filter(item => item.parentLevel == 14 && item.childrenLevel == 3)[0]
          if (this.filterPower) {
            let departments = []
            let data = {}
            //获取权限部门类型
            this.userPowerType = parseInt(this.filterPower.departmentType);
            //判断权限部门
            if (this.filterPower.departmentType == 0) { //本部门
              departments.push(this.UserInfo.dept_id) //传入当前登录用户部门id
              data.deptId = departments.join(',')
              data.isAdmin = 0
            } else if (this.filterPower.departmentType == 1) { //全公司
              data.deptId = ''
              data.isAdmin = 1
            } else if (this.filterPower.departmentType == 2) { //指定部门
              departments.push(this.filterPower.departmentId) //传入部门id
              data.deptId = departments.join(',')
              data.isAdmin = 0
            } else if (this.filterPower.departmentType == 3) { //仅个人
            }
            await api.findFirstLevelDeptUser(data).then(res => {
              if (res.code == 200) {
                this.departmentPower = res.data.departmentList; //赋值并倒叙
              }
            })
          }
        }
      },

      /* 弹框组件 */
      judgeRelatedData(type) {
        if (type == 1) {
          this.show_supplierBox = true;
          this.SHOW_SUPPLIERBOX(true); //供应商弹框
        } else if (type == 2) {
          this.show_departmentBox = true;
          this.SHOW_DEPARTMENTBOX(true); //部门
        } else if (type == 3) {
          this.show_personnelBox = true;
          this.SHOW_PERSONNELBOX(true) //员工弹框
        } else if (type == 4) {
          this.show_customerBox = true;
          this.SHOW_CUSTOMERBOX(true) //客户弹框
        }
      },

      /* 获取所选择的部门数据 */
      getDepartmentData(data) {
        this.show_departmentBox = false;
        let result = this.commonJsExtend.getDeptMsg(data);
        this.checkSearchData.departName = result.nameStr; //部门
        this.checkSearchData.deptId = result.idStr; //部门id
        this.$forceUpdate()
      },

      /* 获取所选择的供应商 */
      getSupplierData(data) {
        
        this.show_supplierBox = false;
        this.checkSearchData.supplierName = data.fullName; //供应商
        this.checkSearchData.supplierId = data.id; //供应商id
      },

      /* 获取所选择的客户数据 */
      getCustomerData(data) {
        this.show_customerBox = false;
        this.checkSearchData.customerName = data.customer_name; //客户
        this.checkSearchData.customerId = data.customer_id; //客户id
        this.$forceUpdate();
      },

      /* 获取员工弹框选择数据*/
      getPersonnelData(data) {
        this.show_personnelBox = false;
        if (data && data.length == 0) {
          return
        }
        //判断选择产品数
        if (data && data.length > 1) {
          this.$message({
            type: 'warning',
            message: '只能选择一个员工!',
            duration: this.elDuration
          })
          return
        }
        this.checkSearchData.userName = data[0].user_name //员工姓名
        this.checkSearchData.userId = data[0].user_id //员工id
        //强制刷新数据($set不能解决,暂用这种方式)
        this.$forceUpdate();
      },

      /* 获取用户权限组件选择出来的数据 */
      getSelectUserPowerData(data) {
        // 判断是否为全部查询
        if (data[0] == -1) { //全部查询
          this.personnel = -1;
        } else { //非全部查询
          this.personnel = data;
        }
        //调用查询方法
        this.findCheckBillsMainByCondition(0);
      },

      /* 根据条件查询列表信息type(0:全部 1:已审 2:未审 5:高级查询)*/
      findCheckBillsMainByCondition(type, isUser) {
        //收款单
        if (this.checkType == 0) {
          //判断是否有查询权限
          if (this.commonJsExtend.isHaveThePower(17, 2, 1, '查询')) {
            this.checkViewData = []
            return
          }
        }
        //付款单
        if (this.checkType == 1) {
          //判断是否有查询权限
          if (this.commonJsExtend.isHaveThePower(17, 1, 1, '查询')) {
            this.checkViewData = []
            return
          }
        }

        this.checkViewButton = type; //把选中的值赋值
        //定义传入后端的参数
        let data = {
          pageIndex: this.pageData.currentPage, //当前页
          pageSize: this.pageData.pageSize, //页面大小
          deleteFlag: 0, //删除标识(0:未作废  1:已作废)
          costClassify: this.checkType, //付款单单据分类
        }

        //判断当前登录类型
        if (this.loginType == 1) { //客户登录(客户必填)
          data.customerId = this.LoginCustomerInfo.customer_id;
        } else if (this.loginType == 2) { //供应商登录(供应商必填)
          data.supplierId = this.LoginSupplierInfo.id;
        }

        let {
          billingNumber, //单号
          supplierName, //供应商
          supplierId, //供应商id
          departName, //部门名
          deptId, //部门id
          customerName, //客户名称
          customerId, //客户id
          userName, //员工名称
          userId, //员工id
          billsType, //单据类型
          startTime, //开始时间
          endTime, //结束时间
          billsStatus, //单据状态
          remark //备注
        } = this.checkSearchData

        //判断查询类型
        if (type == 0) { //全部
        } else if (type == 1) { //已审
          data.isSubmit = 4
        } else if (type == 2) { //未审
          data.isSubmit = 1
        } else if (type == 5) { //高级条件查询
          let today = new Date()
          today.setHours(0, 0, 0, 0)
          if (startTime != '' || endTime != '') { //起止时间其中一个不为空
            if (startTime == '' || endTime == '') { //起止时间其中一个为空
              this.$message({
                type: 'warning',
                message: '查询条件为起止时间时必须两个都填写!',
                duration: this.elDuration
              })
              return
            } else if (endTime.getTime() - startTime.getTime() < 0) { //结束日期不能早于开始日期
              this.$message({
                type: 'warning',
                message: '结束日期不能早于开始日期!',
                duration: this.elDuration
              })
              return
            } else if (today.getTime() < startTime.getTime() || today.getTime() < endTime
              .getTime()) { //所选择的日期不能早于当前日期
              this.$message({
                type: 'warning',
                message: '所选择的日期不能早于当前日期!',
                duration: this.elDuration
              })
              return
            } else { //限制日期选择区间
              let duringTime = (endTime.getTime() - startTime.getTime()) / (24 * 60 * 60 * 1000)
              if (duringTime > 90) {
                this.$message({
                  type: 'warning',
                  message: '日期区间不能超过90天!',
                  duration: this.elDuration
                })
                return
              }
            }
          }
          this.show_searchBox = false
        }
        //判断当前员工查询权限
        if (this.userPowerType == 3) { //仅个人
          data.createUserId = this.UserInfo.user_id;
        } else { //全公司,本部门,指定部门
          //判断是否查询全部
          if (this.personnel == -1) { //查询全部
            let userArr = [];
            this.departmentPower.forEach((itemI, indexI) => {
              if (itemI.userList) {
                itemI.userList.forEach((itemJ, indexJ) => {
                  userArr.push(itemJ.user_id);
                })
              }
            })
            data.createUserId = userArr.join(",");
          } else {
            data.createUserId = this.personnel.join(",");
          }
        }

        //单据类型
        if (billsType != '' && billsType != -1) {
          data.billingTypeId = billsType
        }
        //设置查询条件(单按钮查询不需要带高级查询条件)
        if (![0, 1, 2].includes(type)) {
          //单号
          if (billingNumber != '') {
            data.billsNo = billingNumber
          }
          //供应商
          if (supplierName != '') {
            data.supplierId = supplierId
          }
          //客户
          if (customerName != '') {
            data.customerId = customerId
          }
          //部门名
          if (departName != '') {
            data.deptId = deptId
          }
          //员工名
          if (userName != '') {
            data.userId = userId
          }
          //开始时间
          if (startTime != '') {
            data.startTime = this.commonJsExtend.getDateTime(startTime, 0);
          }
          //结束时间
          if (endTime != '') {
            data.endTime = this.commonJsExtend.getDateTime(endTime, 0);
          }
          //单据状态
          if (billsStatus != '') {
            data.isSubmit = billsStatus
          }
          //备注
          if (remark != '') {
            data.remark = remark
          }
        }

        // 发送请求前加载loading框
        this.loading = this.commonJsExtend.customLoading("#check_tableBox", 4, '数据更新中,请稍候...');
        // 发送请求
        costApi.findAccountCheckBillsByCondition(data).then(res => {
          //关闭弹框
          this.loading.close();
          //验证请求后的结果
          if (res.code == 200) {
            //将数据查询到的数据存入表格数据
            this.checkViewData = res.data.rows;
            //获取分页数据
            this.pageData.totalPage = res.data.total
          } else {
            this.$message({
              type: 'error',
              message: res.message,
              duration: this.elDuration
            })
          }
        })
      },

      /* 审核单据type(0:提交 1:审批  2:驳回  3:对账确认 ) */
      operateCost(type) {
        // 判断是否可以审核
        if (this.checkSelData.length == 0) { //判断为空
          this.$message({
            type: 'warning',
            message: '请选择需要审核的数据!',
            duration: this.elDuration
          })
          return
        } else if (this.checkSelData.length > 1) { //大于1的情况
          this.$message({
            type: 'warning',
            message: '一次只能审核一条数据!',
            duration: this.elDuration
          })
          return
        }
        //将当前选择数据存入data
        this.checkCurrSelData = this.checkSelData[0];
        // 判断哪种操作
        if (type == 0) { //提交
          //定义可提交的状态
          let deleteState = [0, 2] //0:未提交  2:已驳回
          // 判断该数据是否可以提交
          if (!deleteState.includes(this.checkSelData[0].isSubmit)) {
            this.$message({
              type: 'warning',
              message: '当前状态不可提交',
              duration: this.elDuration
            })
            return
          }
          //定义传入后端的值
          let submitData = {
            billingName: this.checkSelData[0].billingTypeInfo.bills_type, //单据类型名称
            billsCheckId: this.checkSelData[0].id, //待付款模块单据id
            processId: this.checkSelData[0].processId,
            statusId: this.checkSelData[0].statusId, //节点id
          }
          // 请求前加载loading框
          this.loading = this.commonJsExtend.customLoading("#check_tableBox", 4, '提交审核中,请稍候...');
          // 发送提交审核请求
          costApi.updateCheckSubmit(submitData).then(res => {
            this.loading.close();
            if (res.code == 200) {
              this.findCheckBillsMainByCondition(this.checkViewButton);
            } else {
              this.$message({
                type: 'error',
                message: res.message,
                duration: this.elDuration
              })
            }
          })
        } else if (type == 1) { //审批(通过)
          //收款单
          if (this.checkType == 0) {
            //判断是否有审核权限
            if (this.commonJsExtend.isHaveThePower(14, 3, 7, '审核')) {
              return
            }
          }
          //付款单
          if (this.checkType == 1) {
            //判断是否有审核权限
            if (this.commonJsExtend.isHaveThePower(17, 1, 7, '审核')) {
              return
            }
          }
          // 定义可审核状态
          let deleteState = [1, 3, 5] //1:审核中  3:复审中   5:终审中
          // 判断该数据是否可以提交
          if (!deleteState.includes(this.checkSelData[0].isSubmit)) {
            this.$message({
              type: 'warning',
              message: '当前状态不可审批',
              duration: this.elDuration
            })
            return
          }

          // 审核确认提示框
          this.$confirm('您确认审核该单据吗,审核后的单据无法修改和作废?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            //获取节点id数组
            let processStatusId = [];
            if (this.checkSelData[0].processStatusId) {
              //节点id转数组
              processStatusId = this.checkSelData[0].processStatusId.split(',');
            }
            //当前节点id
            let statusId = this.checkSelData[0].statusId;
            //当前节点id在数组中的索引
            let statusIdIndex = processStatusId.indexOf(statusId + '');
            //下一节点id
            let nextNodeId = processStatusId[statusIdIndex + 1];
            //判断当前节点是否为最后一个
            if (nextNodeId != processStatusId.pop()) { //当下一节点不为最后一个时
              let nodeNextData = {
                id: nextNodeId - ''
              }
              // 发送请求获取下一节点信息(不需要加载loading框)
              workApi.getWorkflowSetupNodeById(nodeNextData).then(res => {
                if (res.code == 200) {
                  //获取节点基本信息
                  if (res.data.node.working_type == 0) { //当工时类型为人工填写时
                    this.nextNodeBaseInfo = res.data.node;
                    //显示工时弹框
                    this.show_hourseBox = true;
                  } else {
                    //关闭工时弹框
                    this.show_hourseBox = false;
                    this.commitNodeData(res.data.node) //发送审核请求
                  }
                } else {
                  this.$message({
                    type: 'error',
                    message: '节点信息获取失败,请确认...',
                    duration: this.elDuration
                  })
                }
              })
            } else { //当下一节点为最后一个时
              //发送审核请求
              this.commitNodeData({
                working_hours: 0,
                working_hours_type: 0,
                working_type: 0
              })
            }
          }).catch(() => {
            this.$message({
              type: 'info',
              message: '已取消审核',
              duration: this.elDuration
            });
          });
        } else if (type == 2) { //驳回
          //定义可驳回状态
          let rejectState = [1, 3]; //1:审核中  3:复审中
          // 判断是否满足驳回条件
          if (!rejectState.includes(this.checkSelData[0].isSubmit)) {
            this.$message({
              type: 'warning',
              message: '当前状态不可驳回',
              duration: this.elDuration
            })
            return
          }
          //显示驳回弹框
          this.show_reject = true;
          //清空驳回条件
          this.rejectData = "";
        } else if (type == 3) { //对账确认
          this.$confirm('您确认对账该单据吗,对账后的单据无法修改和作废?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            let checkData = {
              billsCheckId: this.checkSelData[0].id, //对账单id
            }
            // 请求前加载loading框
            this.loading = this.commonJsExtend.customLoading("#offer_tableBox", 4, '对账确认中,请稍候...');
            costApi.updateBillSubmitCheck(checkData).then(res => {
              this.loading.close();
              if (res.code == 200) {
                this.$message({
                  type: 'success',
                  message: '对账确认成功!',
                  duration: this.elDuration
                });
                //获取新列表数据
                this.findCheckBillsMainByCondition(this.checkViewButton);
              } else {
                this.$message({
                  type: 'error',
                  message: res.message,
                  duration: this.elDuration
                })
              }
            })
          }).catch(() => {
            this.$message({
              type: 'info',
              message: '已取消对账确认',
              duration: this.elDuration
            });
          });
        }
      },

      /* 审核请求 */
      commitNodeData(dataInfo, nextHourse) {
        //获取工时相关信息
        let {
          working_hours,
          working_hours_type,
          working_type,
        } = dataInfo
        //当员工填写时
        if (nextHourse) {
          working_hours = nextHourse //工时为输入框的值
        }
        //定义传入后端的值
        let submitData = {
          //基本数据
          billingName: this.checkSelData[0].billingTypeInfo.bills_type, //	单据类型名称
          relevanceId: this.checkSelData[0].id, //单据id
          statusId: this.checkSelData[0].statusId, //节点id
          processId: this.checkSelData[0].processId, //流程id
          //通过信息
          workingHours: working_hours, //工时时长
          workingHoursType: working_hours_type, //工时单位
          workingType: working_type, //工时类型
          isFinish: 0, //是否完成
        }
        // 请求前加载loading框
        this.loading = this.commonJsExtend.customLoading("#offer_tableBox", 4, '审核通过中,请稍候...');
        // 发送通过审核请求
        costApi.updateCheckSubmitYes(submitData).then(res => {
          this.loading.close();
          this.show_hourseBox = false; //关闭工时弹框
          if (res.code == 200) {
            this.findCheckBillsMainByCondition(this.checkViewButton);
          } else {
            this.$message({
              type: 'error',
              message: res.message,
              duration: this.elDuration
            })
          }
        })
      },

      /* 判断是否驳回单据 */
      judgeRejectBills(data) {
        //定义可驳回状态
        let rejectState = [1, 3]; //1:审核中  3:复审中
        // 判断是否满足驳回条件
        if (!rejectState.includes(data.isSubmit)) {
          this.$message({
            type: 'warning',
            message: '当前状态不可驳回',
            duration: this.elDuration
          })
          return
        }
        this.$confirm("您确认驳回该数据吗?!", "提示", {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          //将当前驳回数据存入data
          this.checkCurrSelData = data;
          //显示驳回原因弹框
          this.show_reject = true;
          //清空驳回原因
          this.rejectData = "";
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消驳回',
            duration: this.elDuration
          });
        })
      },

      /* 驳回单据 */
      rejectBills() {
        // 判断驳回原因是否已填
        if (this.rejectData.trim() == "") {
          this.$message({
            type: 'warning',
            message: '请填写驳回原因!',
            duration: this.elDuration
          })
          return
        }
        //关闭弹框
        this.show_reject = false;
        //定义传入后端的值
        let submitData = {
          //基本数据
          billingName: this.checkCurrSelData.billingTypeInfo.bills_type, //	单据类型名称
          relevanceId: this.checkCurrSelData.id, //单据id
          statusId: this.checkCurrSelData.statusId, //节点id
          processId: this.checkCurrSelData.processId, //流程id
          isSubmit: 2, //订单状态
          //驳回信息
          rejectReason: this.rejectData, //驳回原因
        }
        // 请求前加载loading框
        this.loading = this.commonJsExtend.customLoading("#check_tableBox", 4, '单据驳回中,请稍候...');
        // 发送驳回请求
        costApi.updateCheckSubmitNo(submitData).then(res => {
          this.loading.close();
          if (res.code == 200) {
            this.$message({
              type: 'success',
              message: '单据驳回成功!',
              duration: this.elDuration
            })
            this.findCheckBillsMainByCondition(this.checkViewButton);
          } else {
            this.$message({
              type: 'error',
              message: res.message,
              duration: this.elDuration
            })
          }
        })
      },

      /* 显示搜索弹框 */
      showSearchBox() {
        this.show_searchBox = !this.show_searchBox
      },


      /* 获取列表已选数据 */
      getSelectedData(selection, row) {
        //获取单选数据
        this.checkSelData = [];
        if (selection.length != 0) {
          selection.forEach(item => {
            this.checkSelData.push(item);
          })
        }
        //设置单选
        // this.$refs.sellTableList.clearSelection();
        // this.$refs.sellTableList.toggleRowSelection(row);
      },

      /* 全选列表数据 */
      getSelectedAllData(selection) {
        //获取数据
        this.checkSelData = [];
        if (selection.length != 0) {
          selection.forEach(item => {
            this.checkSelData.push(item);
          })
        }
        // this.checkSelData = [];
        // //直接清除,不可全选
        // this.$refs.sellTableList.clearSelection();
      },

      //判断是否作废
      judgeDeleteBills(data) {
        //收款单
        if (this.checkType == 0) {
          //判断是否有作废权限
          if (this.commonJsExtend.isHaveThePower(14, 3, 3, '作废')) {
            return
          }
        }
        //付款单
        if (this.checkType == 1) {
          //判断是否有作废权限
          if (this.commonJsExtend.isHaveThePower(17, 1, 3, '作废')) {
            return
          }
        }
        this.$confirm("您确认作废该数据吗?!", "提示", {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          //将当前作废数据存入data
          this.checkCurrSelData = data;
          //显示作废原因弹框
          this.show_delete = true;
          //清空作废原因
          this.deleteData = "";
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消作废',
            duration: this.elDuration
          });
        })
      },

      /* 作废单据 */
      deleteCostData() {
        // 判断作废原因是否已填
        if (this.deleteData.trim() == "") {
          this.$message({
            type: 'warning',
            message: '请填写作废原因!',
            duration: this.elDuration
          })
          return
        }
        //关闭弹框
        this.show_delete = false;
        //定义传入后端的数据
        let deleteData = {
          costCheckMainId: this.checkCurrSelData.id,
          reason: this.deleteData,
          statusId: this.checkCurrSelData.statusId,
          costBillsMainName: this.checkCurrSelData.billingTypeInfo.bills_type
        }
        // 请求前加载loading框
        this.loading = this.commonJsExtend.customLoading("#check_tableBox", 4, '单据作废中,请稍候...');
        // 发送请求
        costApi.deleteCostCheckBillsMain(deleteData).then(res => {
          this.loading.close();
          if (res.code == 200) {
            this.$message({
              type: 'success',
              message: '单据作废成功!',
              duration: this.elDuration
            })
            this.findCheckBillsMainByCondition(this.checkViewButton);
          } else {
            this.$message({
              type: 'error',
              message: res.message,
              duration: this.elDuration
            });
          }
        })
      },

      /* 添加对账单据 */
      addBillsData() {
        console.log("对账单权限未判断");
        //将操作设置为新增
        this.operateType = 0;
        //获取需要回显的单据id(新增为-1)
        this.generateBillsId = -1;
        //设置单据分类类型
        this.billsClassify = this.checkType;
        //设置单据分类code为资金
        this.nodeTypeCode = 'SYS-NODE-TYPE-005';
        //设置默认单据类型
        this.billsTypeDefaultCode = this.checkBillCode;
        //显示操作弹框
        this.show_nodeBills = true;
      },

      /* 修改或预览对账单据 type(1:修改  2:预览) operateOrigin操作来源(不传:本组件  1:单据公共组件)*/
      updateBillData(data, type, operateOrigin) {
        //将操作设置为修改或预览
        this.operateType = type;
        //获取需要回显的单据id
        this.generateBillsId = data.id;
        //设置单据分类类型
        this.billsClassify = this.checkType;
        //设置单据分类code为资金
        this.nodeTypeCode = 'SYS-NODE-TYPE-005';
        //设置默认相关数据
        this.updateDefaultData = {
          nodeId: data.statusId, //节点id
          processId: data.processId, //流程id
        }
        //设置默认单据类型
        if (!operateOrigin) {
          this.billsTypeDefaultCode = data.billingTypeInfo.code;
        }
        //显示操作弹框
        this.show_nodeBills = true;
      },

      /* 获取关联的收款单据信息 */
      getRelvanceDetail(data, sign, classify) {
        //判断是否有相应的权限
        if (this.commonJsExtend.judgeBillsSelPowerBySign(sign, classify)) {
          this.$message({
            type: 'warning',
            message: '管理员未给您开通相应查询权限,请确认!',
            duration: this.elDuration
          })
          return;
        }
        //将操作设置为修改或预览
        this.operateType = 2;
        //获取需要回显的单据id
        this.generateBillsId = data.id;
        //设置单据类型标签(设置为库存模块)
        this.billsTypeSign = sign;
        //设置默认相关数据
        this.updateDefaultData = {
          nodeId: data.statusId, //节点id
          processId: data.processId, //流程id
        }
        //设置默认单据类型
        this.billsTypeDefaultCode = data.billingTypeInfo.code;
        //设置单据分类类型
        this.billsClassify = classify;
        //设置单据分类code
        this.nodeTypeCode = this.commonJsExtend.findNodeTypeCodeBySign(sign);
        //显示操作弹框
        this.show_nodeBills = true;
      },

      /*初始化列表显示字段数据 */
      initListShowFiledData() {
        //获取界面显示数组
        this.showListFiledArr = this.commonJsExtend.initListShowFiledData('costCheckListArr', this.checkListFileData)
          .showListFiledArr;
        //获取控制数组
        this.showListFiledData = this.commonJsExtend.initListShowFiledData('costCheckListArr', this.checkListFileData)
          .showListFiledData;
      },

      /* 获取当前需要显示的列表字段 */
      getCurShowFiledArr() {
        //将数据存入cookie
        this.showListFiledArr = this.commonJsExtend.getCurShowFiledArr('costCheckListArr', this.showListFiledData);
      },

      /* 设置查看单据状态为预览(子组件调用) */
      setOperateTypeIsView() {
        this.operateType = 2;
      },

      /* 关闭单据公共弹框 */
      closeNodeBills() {
        this.show_nodeBills = false;
      },

      /* 取消工时弹框 */
      closeHourseBox() {
        this.show_hourseBox = false;
      },

      /* 分页数据大小改变 */
      changePageSize(val) {
        //获取分页大小
        this.pageData.pageSize = val;
        //请求数据
        this.findCheckBillsMainByCondition(this.checkViewButton)
      },

      /* 当前页改变 */
      changeCurrentPage(val) {
        //获取当前页
        this.pageData.currentPage = val;
        //请求数据
        this.findCheckBillsMainByCondition(this.checkViewButton)
      },

      /* 获取合计数据 */
      getSummaries(param) {
        const {
          columns,
          data
        } = param;
        const sums = [];
        columns.forEach((column, index) => {
          if (index === 0) {
            sums[index] = '总计';
            return;
          }
          let sumPropertys = ['totalAmount', 'totalTaxAmount', 'exportNum', 'printNum']; //定义需要计算的字段
          const values = data.map(item => Number(item[column.property]));
          if (sumPropertys.indexOf(column.property) != -1) {
            if (!values.every(value => isNaN(value))) {
              sums[index] = values.reduce((prev, curr) => {
                const value = Number(curr);
                if (!isNaN(value)) {
                  return prev + curr;
                } else {
                  return prev;
                }
              }, 0);
              sums[index] += '';
            } else {
              sums[index] = '';
            }
          }
        });
        return sums;
      }
    },
    components: {
      nodeBills,
      departmentTree,
      supplierList,
      customerList,
      personnelList,
      userPowerSelect,
      billExport,
      rejectBillTips,
      relvanceBillTipStrip
    }
  }
</script>
